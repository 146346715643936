import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import ModalPortfolio from '@solid-ui-blocks/Modal/Block03'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import ContentOne from '@solid-ui-blocks/Content/Block01'
import ContentTwo from '@solid-ui-blocks/Features/Block06'
import Footer from '@solid-ui-blocks/Footer/Block02'
import Team from '@solid-ui-blocks/Teams/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const Resources = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Resources' indexed={false} />
      <ModalWithTabs content={content['contact']} />
      <ModalPortfolio content={content['dr-asim-eladl']} />
      <ModalPortfolio content={content['dr-christos-s-giannoulias']} />
      <ModalPortfolio content={content['dr-darrel-saldanha']} />
      <ModalPortfolio content={content['dr-demetrios-john-giokaris']} />
      <ModalPortfolio content={content['dr-krishna-c-chunduri']} />
      <ModalPortfolio content={content['dr-thomas-poepping']} />
      <ModalPortfolio content={content['dr-timothy-j-heilizer']} />
      <ModalPortfolio content={content['ruben-bermudez-pa']} />
      <ModalPortfolio content={content['dr-asim-eladl']} />
      <Header content={content['header']} />
      <Divider space='6' />
      <Hero content={content['hero']} reverse />
      <Divider space='4' />
      <Container variant='wide'>
        <ContentOne content={content['content-one']} />
      </Container>
      <Divider space='4' />
      <Divider space='4' />
      <Team content={content['all-physicians']} />
      <Divider space='4' />
      <Footer content={content['footer2']} />
    </Layout>
  )
}

export const query = graphql`
  query pageRandResourcesBlockContent {
    allBlockContent(filter: { page: { in: ["site/resources", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Resources
